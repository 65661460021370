import styled, {css} from "styled-components";
import {themeType} from "../theme/GlobalStyles";
import {Container} from "@components/container";

export const HeaderTextWrapper = styled(Container)`
display: inline-grid;
justify-content: center;
grid-auto-rows: minmax(min-content, max-content);
`;

export const HeaderText = styled.div<Partial<{theme: themeType, center: boolean}>>`
  margin-bottom: 24px;
  font-weight: bold;
  font-size: ${({theme}) => theme.fontSizes.L};

  ${({center}: any) => center && css`
    text-align: center;
  `}
`;

export const SubHeaderText = styled.div<Partial<{theme: themeType, bold: boolean}>>`
  margin-bottom: 12px;

  ${({bold}: any) => bold && css`
    font-weight: bold;
  `}

  ${({center}: any) => center && css`
    text-align: center;
  `}
`;

type TextType = { theme: themeType, color: string, className: string, bold: boolean, fontSize: string };

export const GPText = styled.span<Partial<TextType>>`
  ${({bold}: any) => bold && css`
    font-weight: bold;
  `}
  
  font-size: ${({theme, fontSize}) => fontSize || theme.fontSizes.S};
  color: ${({theme, color}) => color || theme.colors.text};
`;
