import React from "react";
import styled from "styled-components";

const Page = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100vh;
`;

export default Page;
