import {ListingModel} from "@shared/models/listing.model";
import {GPText} from "@components/text";
import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {storage} from "../init-firebase";

export function JobInfoImage({job}: {job: ListingModel}) {
  const [imageURL, setImageUrl] = useState();

  useEffect(()=> {
    async function image() {
      setImageUrl(await storage.refFromURL(job.image!).getDownloadURL());
    }

    image();
  }, []);

  return (
    <StyledJobInfoImage bgImage={imageURL}>
      <div className={"venue"}>
        <GPText>{job.name}</GPText>
        <GPText>{job.ownerName}</GPText>
      </div>
      <div className={"date"}>
        <GPText>{job.date}</GPText>
      </div>
    </StyledJobInfoImage>
  );
}

const StyledJobInfoImage = styled.div<{bgImage?: any}>`
  padding: ${({theme}) => `calc(${theme.dimensions.margin} / 2)`};
  height: ${({theme}) => `calc(${theme.dimensions.margin} * 6)`};

  ${({bgColor}: any) => bgColor && css`
    background-color: ${({theme}) => theme.colors.accent};
  `}
  
  ${({bgImage}: any) => bgImage ? css`
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 50%, rgba(0, 0, 0, 1) 100%), 
    url(${bgImage});
    background-repeat: no-repeat;
    background-size: cover;
  `: css`
    background-color: ${({theme}) => theme.colors.accent};
  `}
  
  display: grid;
  grid-template-areas: 
          '. .'
          'venue date';
  
  justify-content: space-between;
  .venue {
    grid-area: venue;
    align-self: flex-end;
    
    display: grid;
  }
  
  .date {
    grid-area: date;
    align-self: flex-end;
  }
`;
