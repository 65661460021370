import React, {createContext, useEffect, useState} from "react";
import {auth, firestore} from "../init-firebase";
import {COLLECTION_EMPLOYEES, COLLECTION_PARTNERS} from "@shared/firebase-vars";
import {EmployeeModel} from "@shared/models/employee.model";
import {userRoles} from "@shared/enums/userRoles.enum";
import {PartnerModel} from "@shared/models/partner.model";

export type authState<U = any> = {user?: U |null, checkingStatus: boolean, role?: userRoles | null};

const defaultAuth: authState = {user: null, checkingStatus: true, role: null};
export const AuthContext = createContext<authState>(defaultAuth);

export default function UserAuthContext({children}: any) {
  const [authentication, setAuthentication] = useState<authState>( defaultAuth );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      let data = null;
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        const role = idTokenResult.claims["roles"][0].toUpperCase();
        const collection = role === userRoles.employee ?
          COLLECTION_EMPLOYEES : role === userRoles.partner ?
            COLLECTION_PARTNERS : null;

        if (collection) {
          firestore
              .collection(collection)
              .doc(user.uid)
              .onSnapshot(async (docSnap) => {
                data = docSnap.data() as EmployeeModel | PartnerModel;
                const userWithId = Object.assign({}, data, {uid: user.uid});
                setAuthentication({user: userWithId, checkingStatus: false, role});
              });
        }
      } else {
        setAuthentication({user: null, checkingStatus: false, role: null});
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={authentication}>
      {children}
    </AuthContext.Provider>
  );
}
