import styled from "styled-components";

export const Container = styled.section<Partial<{doublePadding: boolean, zIndex: number, height: string}>>`
  --sidePadding: 24px;
  width: 100%;
  box-sizing: border-box;
  height: ${({height}) => height || "30vh"};
  z-index: ${({zIndex}) => zIndex || 0};
  padding: ${({doublePadding}) => doublePadding ? "0 calc(var(--sidePadding) * 2)": "var(--sidePadding)"};
`;
