import {api} from "../api";

export async function buyCredits(amount: number, mangoId: string) {
  const paymentData = await api.buyCredits(amount * 100, mangoId);
  window.location.href = paymentData.RedirectURL;
}
export async function getCredits(mangoId: string) {
  const response = await api.getCredits(mangoId);
  return response.balance.Amount / 100;
}
