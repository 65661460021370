import React, {useEffect, useState} from "react";
import {Container} from "@components/container";
import {HeaderText, SubHeaderText} from "@components/text";
import {useParams} from "react-router-dom";
import {firestore} from "../init-firebase";
import {COLLECTION_LISTINGS, COLLECTION_REQUESTS} from "@shared/firebase-vars";
import {RequestModel} from "@shared/models/request.model";
import {ListingModel} from "@shared/models/listing.model";
import styled from "styled-components";
import {RequestStatus} from "@shared/enums/requestStatus.enum";

export function JobAdmin() {
  const {id} = useParams<{id: string}>();

  const [job, setJob] = useState<ListingModel | null>( null );
  const [applications, setApplications] = useState<RequestModel[]>( [] );

  useEffect(() => {
    const unsubscribe1 = firestore
        .collection(COLLECTION_LISTINGS)
        .doc(id)
        .onSnapshot( (colSnap) => {
          const data = colSnap.data() as any;
          if (data) {
            const listing = Object.assign({}, data, {id: colSnap.id, date: data.date.toDate().toLocaleDateString()});
            setJob(listing);
          }
        });

    const unsubscribe2 = firestore
        .collection(COLLECTION_LISTINGS)
        .doc(id)
        .collection(COLLECTION_REQUESTS)
        .onSnapshot( (colSnap) => {
          if (!colSnap.empty) {
            const temp: RequestModel[] = [];
            for (const doc of colSnap.docs) {
              temp.push(Object.assign({}, doc.data() as RequestModel, {id: doc.id}));
            }
            setApplications(temp);
          }
        });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, []);

  return (
    <Container>
      <HeaderText>Job Admin</HeaderText>

      <SubHeaderText bold>Applicants</SubHeaderText>
      {
        applications.length > 0 ? applications.map( (application, i) => (
          <ApplicationRow key={i}>
            <span>{application.employeeName}</span>
            <ActionBox>
              <span onClick={() => acceptApplication(application)}
                className={`circle check ${isActive(application.status, RequestStatus.accepted)}`}></span>
              <span onClick={() => declineApplication(application)}
                className={`circle ban ${isActive(application.status, RequestStatus.declined)}`}></span>
            </ActionBox>
          </ApplicationRow>
        )) :
          <div>No applications yet</div>
      }

    </Container>
  );
}

const ApplicationRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => `0 ${theme.dimensions.margin} `};
  height: ${({theme}) => `calc(${theme.dimensions.margin} * 2)`};
  margin: ${({theme}) => `${theme.dimensions.margin} 0`};
  background-color: ${({theme}) => theme.colors.grey};
`;

const ActionBox = styled.div`
  display: grid;
  grid-gap: ${({theme}) => theme.dimensions.margin};
  grid-auto-flow: column;
  
  .circle {
    border: 1px white solid;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
  }
  
  .check {
    &.active {
      background-color: green;
    }
  }

  .ban {
    &.active {
      background-color: red;
    }
  }
  
  .check:after {
    content: "\\2713";
    font-size: 1.5em;
  }

  .ban:after {
    content: "\\2298";
    font-size: 1.5em;
  }
`;

async function acceptApplication(application: RequestModel) {
  if (application.status !== RequestStatus.accepted) {
    await firestore.collection(COLLECTION_LISTINGS)
        .doc(application.listingId)
        .collection(COLLECTION_REQUESTS)
        .doc(application.id)
        .set({status: RequestStatus.accepted}, {merge: true});
  }
}

async function declineApplication(application: RequestModel) {
  if (application.status !== RequestStatus.declined) {
    await firestore.collection(COLLECTION_LISTINGS)
        .doc(application.listingId)
        .collection(COLLECTION_REQUESTS)
        .doc(application.id)
        .set({status: RequestStatus.declined}, {merge: true});
  }
}

function isActive(status: string, condition: RequestStatus) {
  return status === condition ? "active" : "";
}
