import React from "react";
import Requester from "./requester";

import "./requestContainer.scss";
import RequestsList from "./requestsList";

function RequestContainer() {
  return (
    <div>
      <h3>Request</h3>
      <div className="RequestContainer">
        <Requester />
        <RequestsList />
      </div>
    </div>
  );
}


export default RequestContainer;
