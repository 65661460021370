import {ListingModel} from "@shared/models/listing.model";
import {HeaderText} from "@components/text";
import React, {useContext} from "react";
import styled from "styled-components";
import {firestore} from "../init-firebase";
import {Container} from "@components/container";
import {Field, Form, Formik} from "formik";
import {PartnerModel} from "@shared/models/partner.model";
import {COLLECTION_LISTINGS} from "@shared/firebase-vars";
import {AuthContext, authState} from "../user/userAuthContext";
import {Button} from "@components/button";
import {goToJobsPage} from "../helpers/redirects";

export function JobCreate({history}: {history: any}) {
  const {user} = useContext<authState<PartnerModel>>(AuthContext);

  const reader: any = new FileReader();

  return (
    <Container>
      <HeaderText>Create</HeaderText>

      {user && <Formik
        initialValues={{
          name: "",
          date: "",
          rate: 0,
          hours: 0,
          description: "",
          image: "",
          pax: 0}}
        onSubmit={async ({name, description, date, pax, rate, hours, image}) => {
          const newListing = await createNewListing({
            name,
            ownerId: user.uid,
            ownerName: user.organisationName, date: new Date(date), rate, hours, image, description, pax});

          goToJobsPage(newListing.id, history);
        }
        }
      >{({errors, setFieldValue}) =>
          <Form>
            <FieldGroup>
              <Label>name</Label>
              <Input name="name" placeholder="name of the event" type="text"/>
            </FieldGroup>
            <FieldGroup>
              <label>how many personal needed</label>
              <Input name="pax" placeholder="how many personal needed" type="number"/>
            </FieldGroup>
            <FieldGroup>
              <Label>hourly rate</Label>
              <Input name="rate" placeholder="rate per hour" type="number"/>
            </FieldGroup>
            <FieldGroup>
              <Label>hours</Label>
              <Input name="hours" type="number"/>
            </FieldGroup>
            <FieldGroup>
              <Label>description</Label>
              <Input name="description" placeholder="description of event" type="textArea"/>
            </FieldGroup>
            <FieldGroup>
              <Label>date</Label>
              <Input name="date" placeholder="date of event" type="date"/>
            </FieldGroup>
            <FieldGroup>
              <Label>Image</Label>
              <input id="image" placeholder="Image shown to applicants" name="image" type="file" onChange={(event: any) => {
                reader.onloadend = () => {
                  const base64String = reader.result
                      .replace("data:", "")
                      .replace(/^.+,/, "");

                  setFieldValue("image", base64String);
                };
                reader.readAsDataURL(event.currentTarget.files[0]);
              }}/>
            </FieldGroup>
            <Button type="submit">Submit</Button>
          </Form>
        }
      </Formik>}
    </Container>
  );
}

export const FieldGroup = styled.div`
  margin-bottom: ${({theme}) => `calc(${theme.dimensions.margin})`};
`;

export const Label = styled.label`
  margin-top: 1.5rem;
  width: 100%;
`;

export const Input = styled(Field)`
  color: black;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.75rem 0.75rem;
`;

async function createNewListing(listing: Omit<ListingModel, "status"|"created"|"id">) {
  try {
    return await firestore.collection(COLLECTION_LISTINGS).add(listing);
  } catch (e) {
    throw Error(e);
  }
}
