import React, {useEffect, useState} from "react";
import {firestore} from "../../init-firebase";
import Request from "./request";
import {RequestModel} from "@shared/models/request.model";
import {COLLECTION_REQUESTS} from "@shared/firebase-vars";

function RequestsList() {
  const [requests, setRequests] = useState<RequestModel[]>();

  useEffect(() => {
    const unsubscribe = firestore.collectionGroup(COLLECTION_REQUESTS).onSnapshot((snap) => {
      const data = snap.docs.map((d: any) => {
        const list = d.data() as RequestModel;
        return {...list, id: d.id};
      },
      );

      setRequests(data);

      return function cleanup() {
        unsubscribe();
      };
    });
  }, []);

  return (
    <div>
      <h4>Requests</h4>

      {requests && requests.length > 0 ?
        requests?.map( (request) => <Request key={request.id} request={request} />) :
        null}

    </div>
  );
}

export default RequestsList;
