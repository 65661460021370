import {auth} from "../init-firebase";

export async function login(email: any, password: any, history: any) {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    history.push("/jobs");
  } catch (e) {
    console.error(e);
  }
}

export async function logout() {
  await auth.signOut();
}
