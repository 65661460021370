import React, {useContext, useEffect, useState} from "react";
import {ListingModel} from "@shared/models/listing.model";
import {firestore} from "../init-firebase";
import {COLLECTION_LISTINGS, COLLECTION_REQUESTS} from "@shared/firebase-vars";
import Page from "@components/page";
import styled, {css} from "styled-components";
import {Container} from "@components/container";
import {useParams} from "react-router-dom";
import {SubHeaderText} from "@components/text";
import {JobInfoImage} from "./JobInfoImage";
import {Button} from "@components/button";
import {RequestModel} from "@shared/models/request.model";
import {AuthContext, authState} from "../user/userAuthContext";
import {goToApplicationPage} from "../helpers/redirects";
import {userRoles} from "@shared/enums/userRoles.enum";
import {EmployeeModel} from "@shared/models/employee.model";

const tabKeys = ["Job Info", "Organisation"];

function Jobs({history}: {history: any}) {
  const {id} = useParams<{id: string}>();

  const {user, role} = useContext<authState<EmployeeModel>>(AuthContext);

  const [job, setJob] = useState<ListingModel | null>( null );
  const [hasApplied, setHasApplied] = useState<{
    checked: boolean,
    request: any,
    applied: boolean
  }>({checked: false, request: null, applied: false} );
  const [activeTab, setActiveTab] = useState<number>( 0 );

  async function applyToJob() {
    if (user && job) {
      const request: Partial<RequestModel> = {
        employeeId: user.uid,
        employeeName: user.name,
        owner: job.ownerId,
        listingId: job.id,
        listingName: job.name,
        ownerName: job.ownerName,
      };

      const documentReference = await firestore
          .collection(COLLECTION_LISTINGS)
          .doc(job.id)
          .collection(COLLECTION_REQUESTS)
          .add(request);

      goToApplicationPage(job.id, documentReference.id, history);
    }
  }

  useEffect(() => {
    let unsubscribe2: any;

    function checkIfApplied(listing: ListingModel) {
      return firestore
          .collection(COLLECTION_LISTINGS)
          .doc(listing.id)
          .collection(COLLECTION_REQUESTS)
          .where("employeeId", "==", user?.uid)
          .onSnapshot((colSnap) => {
            let request;
            if (!colSnap.empty) {
              const doc = colSnap.docs[0];
              const data = doc.data();
              request = Object.assign({}, data, {id: doc.id});
            }
            setHasApplied({applied: !colSnap.empty, request, checked: true});
          });
    }

    const unsubscribe1 = firestore
        .collection(COLLECTION_LISTINGS)
        .doc(id)
        .onSnapshot( (colSnap) => {
          const data = colSnap.data() as any;
          if (data) {
            const listing = Object.assign({}, data, {id: colSnap.id, date: data.date.toDate().toLocaleDateString()});
            unsubscribe2 = checkIfApplied(listing);
            setJob(listing);
          }
        });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, []);

  return (
    <JobDetailPage>
      {job &&
      <>
        <JobInfoImage job={job}/>
        <JobContainer>
          {
            role === userRoles.employee &&
              hasApplied.checked &&
              !hasApplied.applied ?
            <Button onClick={() => applyToJob()} type={"button"}>APPLY NOW</Button> :
            <Button onClick={() => goToApplicationPage(job?.id, hasApplied.request.id, history)} type={"button"}>
              SEE APPLICATION STATUS
            </Button>
          }
          <Tabs>
            <TabLabels>
              {tabKeys.map( (key: string, i: number) =>
                (
                  <StyledSubHeaderText onClick={() => setActiveTab(i)} active={activeTab === i} key={key} bold={true}>
                    {key}
                  </StyledSubHeaderText>
                ),
              )}
            </TabLabels>
            <div>
              {
              activeTab === 0 ? job.description : activeTab === 1 ? job.ownerName : null
              }
            </div>
          </Tabs>
        </JobContainer>
      </>
      }
    </JobDetailPage>
  );
}

const TabLabels = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-bottom: 12px;
  text-align: center;
`;

const StyledSubHeaderText = styled(SubHeaderText)<{active: boolean}>`
  position: relative;

  ${({active}: any) => active && css`
    &:after {
      position: absolute;
      content: '';
      height: 4px;
      top: 24px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 75%;
      transition: .5s;
      background: white;
    }
  `}
`;

const Tabs = styled.div`
  position: relative;
`;

const JobContainer = styled(Container)<{theme: any}>`
  font-family: ${({theme}) => theme.fonts.secondary};
  height: 100%;
`;

const JobDetailPage = styled(Page)`
  display: grid;
  grid-template-areas:
          "jobInfoImage"
  "content"
;

  grid-template-rows: auto 1fr;

  ${JobContainer} {
    grid-area: content;
    display: grid;
    grid-template-rows: min-content;
    grid-gap: 24px;
  }
`;

export default Jobs;
