import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import {commerce, name} from "faker/locale/nl";
import {firestore} from "../../init-firebase";
import {COLLECTION_LISTINGS, COLLECTION_PARTNERS} from "@shared/firebase-vars";
import {ListingModel} from "@shared/models/listing.model";
import {PartnerModel} from "@shared/models/partner.model";

function CreateListing() {
  const [listing, setListing] = useState<any>();
  const [partners, setPartners] = useState<PartnerModel[]>();

  const reader: any = new FileReader();

  useEffect(()=>{
    const unsubscribe = firestore.collection(COLLECTION_PARTNERS).onSnapshot((snap) => {
      const data = snap.docs.map((d: any) => {
        const list = d.data() as PartnerModel;
        return {...list, uid: d.id};
      });

      setPartners(data);
    });

    return function cleanup() {
      unsubscribe();
    };
  }, []);

  return (
    <div>

      <h4>Create listing</h4>

      { partners && <Formik
        initialValues={{
          name: name.firstName(),
          ownerId: partners[0]?.uid,
          ownerName: "",
          date: new Date(),
          rate: Math.floor(Math.random() * 100),
          hours: Math.floor(Math.random() * 10),
          description: commerce.productDescription(),
          image: "",
          pax: Math.floor(Math.random() * 10)}}
        onSubmit={async ({name, ownerId, ownerName, description, date, pax, rate, hours, image}) => {
          setListing(
              await createNewListing({name, ownerId, ownerName, date: new Date(date), rate, hours, image, description, pax}),
          );
        }}
      >{({errors, setFieldValue}) =>
          <Form>
            <div>
              <label>name</label>
              <Field name="name" type="text"/>
            </div>
            <div>
              <label>needed</label>
              <Field name="pax" type="number"/>
            </div>
            <div>
              <label>rate</label>
              <Field name="rate" type="number"/>
            </div>
            <div>
              <label>hours</label>
              <Field name="hours" type="number"/>
            </div>
            <div>
              <label>ownerName</label>
              <Field as="select" name="ownerName" onChange={(e: any) => {
                const value = e.target.value;
                setFieldValue("ownerName", value);
                setFieldValue("ownerId", find<PartnerModel>(partners, value, "name").uid);
              }}
              onClick={(e: any) => {
                const value = e.target.value;
                setFieldValue("ownerId", find<PartnerModel>(partners, value, "name").uid);
              }
              }>
                {selectOptions<PartnerModel>(partners, "name")}
              </Field>
            </div>
            <div>
              <label>ownerId</label>
              <Field disabled name="ownerId" type="text"/>
            </div>
            <div>
              <label>description</label>
              <Field name="description" type="text"/>
            </div>
            <div>
              <label>date</label>
              <Field name="date" type="date"/>
            </div>
            <div>
              <label>Image</label>
              <input id="image" name="image" type="file" onChange={(event: any) => {
                reader.onloadend = () => {
                  const base64String = reader.result
                      .replace("data:", "")
                      .replace(/^.+,/, "");

                  setFieldValue("image", base64String);
                };
                reader.readAsDataURL(event.currentTarget.files[0]);
              }}/>
            </div>
            <button type="submit">Submit</button>
          </Form>
        }
      </Formik>
      }
      {listing ? "successfully created listing" : null}
    </div>
  );
}

async function createNewListing(listing: Omit<ListingModel, "status"|"created"|"id">) {
  try {
    return await firestore.collection(COLLECTION_LISTINGS).add(listing);
  } catch (e) {
    throw Error(e);
  }
}

function selectOptions<T>(values: T[], selector: string) {
  return values?.map( (value: { [key: string]: any }, i: number) =>{
    return (
      <option key={i} value={value[selector]}>{value[selector]}</option>
    );
  } );
}

function find<T>(values: T[], comparer: string, selector: string) {
  const found = values.find( (value: { [key: string]: any }) => {
    return value[selector] === comparer;
  });

  if (!found) throw Error(`${comparer} with selector ${selector} not found`);

  return found;
}

export default CreateListing;
