import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {internet, name} from "faker/locale/nl";
import {api} from "../../api";
import firebase from "firebase/app";

import "./Employee.scss";
import {createEmployeeAccount} from "@shared/models/employee.model";

function CreateEmployee() {
  const [userCredential, setUserCredential] = useState<firebase.UserInfo>();

  return (
    <div className="Employee">
      <h4>Create Employee</h4>
      <Formik
        initialValues={{
          firstname: name.firstName(),
          lastname: name.lastName(),
          email: internet.email(),
          password: internet.password(10),
          kvk: 69599084,
          birthday: new Date()}}
        onSubmit={async ({firstname, lastname, email, password, kvk, birthday}) => {
          setUserCredential(await createEmployee({firstname, lastname, email, password, kvk, birthday}));
        }}
      >
        <Form>
          <div>
            <label>firstname</label>
            <Field name="firstname" type="text" />
          </div>
          <div>
            <label>lastname</label>
            <Field name="lastname" type="text" />
          </div>
          <label>email</label>
          <Field name="email" type="email" />
          <div>
            <label>kvk</label>
            <Field name="kvk" type="number" />
          </div>
          <div>
            <label>birthday</label>
            <Field name="birthday" type="date" />
          </div>
          <label>password</label>
          <Field name="password" type="password" />
          <button type="submit">Submit</button>
        </Form>
      </Formik>
      {userCredential ?
          <div> Employee <span>{userCredential?.displayName}</span>
              with id: <span>{userCredential?.uid}</span> has been created</div>: null}
    </div>
  );
}

async function createEmployee(employee: createEmployeeAccount) {
  return await api.createEmployee(employee);
}

export default CreateEmployee;
