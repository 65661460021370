import {createGlobalStyle} from "styled-components";
import {useTheme} from "./useTheme";

const theme = useTheme();

export type themeType = typeof theme;

export const GlobalStyles = createGlobalStyle<themeType>`
  body {
    margin: 0;
    font-family: ${({theme}) => theme.fonts.primary};
    font-size: ${({theme}) => theme.fontSizes.S};
    background-color: ${({theme}) => theme.colors.body};
    color: ${({theme}) => theme.colors.text};
  }
  
  button {
    border: none;
  }

  a:visited {
    text-decoration: none;
    color: ${({theme}) => theme.colors.link.text};
  }
  
  input {
    height: 4em;
    box-sizing: border-box;
    padding: 1em;
    font-size: 16px;
    border: ${({theme}) => theme.colors.input.border};
    background-color: ${({theme}) => theme.colors.input.backgroundColor};
    color: ${({theme}) => theme.colors.text};
    max-width: 100%;
  }
`;
