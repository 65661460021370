import axios from "axios";
import {resolve} from "./resolve";
import {createEmployeeResponse} from "./types";
import firebase from "firebase/app";
import {createEmployeeAccount} from "@shared/models/employee.model";
import {createPartnerAccount} from "@shared/models/partner.model";

const baseUrl = process.env.REACT_APP_BASEURL;

async function createEmployee(data: createEmployeeAccount): Promise<firebase.UserInfo> {
  const res = await resolve<createEmployeeResponse>(axios.post(`${baseUrl}/employee`, data));
  return res.response.data.data;
}

async function createPartner(data: createPartnerAccount): Promise<firebase.UserInfo> {
  const res = await resolve<createEmployeeResponse>(axios.post(`${baseUrl}/partner`, data));
  return res.response.data.data;
}

async function buyCredits(amount: number, mangoId: string) {
  const res = await resolve<any>(axios.post(`${baseUrl}/payment/credit`, {amount, mangoId}));
  return res.response.data.data;
}

async function getCredits(mangoId: string) {
  const res = await resolve<any>(axios.get(`${baseUrl}/payment/credit?mangoId=${mangoId}`));
  return res.response.data.data;
}

async function createBankAccount(userId: string, iban: string) {
  const res = await resolve<any>(axios.post(`${baseUrl}/payment/bank-account`, {userId, iban}));
  return res.response.data.data;
}

async function postIdentityProof(userId: string, front: string, back: string) {
  const res = await resolve<any>(axios.post(`${baseUrl}/kyc/identity-proof`, {userId, front, back}));
  return res.response.data.data;
}

async function postKvkUitreksel(userId: string, uitreksel: string) {
  const res = await resolve<any>(axios.post(`${baseUrl}/kyc/kvk`, {userId, uitreksel}));
  return res.response.data.data;
}

export const api = {
  createEmployee,
  buyCredits,
  postIdentityProof,
  postKvkUitreksel,
  createBankAccount,
  createPartner,
  getCredits,
};
