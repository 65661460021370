import React from "react";
import CreatePartner from "./CreatePartner";

import "./partner.scss";

function PartnerContainer() {
  return (
    <div className="Partner">
      <h3>Partner</h3>
      <CreatePartner />
    </div>
  );
}

export default PartnerContainer;
