import {firestore} from "../init-firebase";
import {COLLECTION_LISTINGS, COLLECTION_REQUESTS} from "@shared/firebase-vars";
import {RequestStatus} from "@shared/enums/requestStatus.enum";
import {RequestModel} from "@shared/models/request.model";

export async function completeApplication(job: string, request: RequestModel) {
  await firestore
      .collection(COLLECTION_LISTINGS)
      .doc(job)
      .collection(COLLECTION_REQUESTS)
      .doc(request.id)
      .set({status: RequestStatus.completed}, {merge: true});
}
