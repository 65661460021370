import {GPText, HeaderText} from "@components/text";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext, authState} from "./userAuthContext";
import {firestore} from "../init-firebase";
import {COLLECTION_EMPLOYEES} from "@shared/firebase-vars";
import styled from "styled-components";
import {Container} from "@components/container";
import Page from "@components/page";
import {EmployeeModel} from "@shared/models/employee.model";
import bonfireImageSmall from "../assets/start-welcome-image.png";
import {Form, Formik} from "formik";
import {FileInput, Input} from "@components/input";
import {Button} from "@components/button";
import {api} from "../api";


function EmployeeProfile() {
  const {user} = useContext<authState>(AuthContext);

  const reader: any = new FileReader();

  const [employee, setEmployee] = useState<EmployeeModel | null>( null );

  useEffect(() => {
    const unsubscribe1 = firestore
        .collection(COLLECTION_EMPLOYEES)
        .doc(user?.uid)
        .onSnapshot( (colSnap) => {
          const data = colSnap.data() as EmployeeModel;
          if (data) {
            const temp = Object.assign({}, data, {uid: colSnap.id});
            setEmployee(temp);
          }
        });
    return () => {
      unsubscribe1();
    };
  }, []);

  return (
    <Page>
      <ProfileContainer>
        <HeaderText>Profile</HeaderText>
        <PhotoAndContact>
          <PhotoBox />
          <div>
            <GPText bold>{employee?.name}</GPText>
          </div>
        </PhotoAndContact>
        <About>
          <GPText bold>About You</GPText>
        </About>
        <Finance>
          <GPText bold>Finance</GPText>
          <Formik
            initialValues={{iban: ""}}
            onSubmit={async ({iban}) => {
              if (employee) {
                await api.createBankAccount(employee?.uid, iban);
              }
            }}
          >
            <StyledForm>
              <Input label={"IBAN"} name={"iban"} type={"text"}/>
              <Button type={"submit"}>Submit</Button>
            </StyledForm>
          </Formik>
        </Finance>
        <DocumentsCenter>
          <div>
            <GPText bold>Documents</GPText>

            <Formik
              initialValues={{front: "", back: ""}}
              onSubmit={async ({front, back}) => {
                if (employee) {
                  await api.postIdentityProof(employee.uid, front, back);
                }
              }}
            >{({setFieldValue}) =>
                <StyledForm>
                  <GPText>Identity</GPText>
                  <FileInput label={"Front"} id="front" name="front" type="file" onChange={(event: any) => {
                    reader.onloadend = () => {
                      const base64String = reader.result
                          .replace("data:", "")
                          .replace(/^.+,/, "");

                      setFieldValue("front", base64String);
                    };
                    reader.readAsDataURL(event.currentTarget.files[0]);
                  }}/>
                  <FileInput label={"Back"} id="back" name="back" type="file" onChange={(event: any) => {
                    reader.onloadend = () => {
                      const base64String = reader.result
                          .replace("data:", "")
                          .replace(/^.+,/, "");

                      setFieldValue("back", base64String);
                    };
                    reader.readAsDataURL(event.currentTarget.files[0]);
                  }}/>
                  <Button type={"submit"}>Submit</Button>
                </StyledForm>
              }
            </Formik>

            <Formik
              initialValues={{uitreksel: ""}}
              onSubmit={async ({uitreksel}) => {
                if (employee) {
                  await api.postKvkUitreksel(employee?.uid, uitreksel);
                }
              }}
            >{({setFieldValue}) =>
                <StyledForm>
                  <GPText>KVK Uitreksel</GPText>
                  <FileInput label={""} id="uitreksel" name="uitreksel" type="file" onChange={(event: any) => {
                    reader.onloadend = () => {
                      const base64String = reader.result
                          .replace("data:", "")
                          .replace(/^.+,/, "");

                      setFieldValue("uitreksel", base64String);
                    };
                    reader.readAsDataURL(event.currentTarget.files[0]);
                  }}/>
                  <Button type={"submit"}>Submit</Button>
                </StyledForm>
              }
            </Formik>
          </div>
        </DocumentsCenter>
      </ProfileContainer>
    </Page>
  );
}

const StyledForm = styled(Form)`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

const DocumentsCenter = styled.div`
  display:grid;
  grid-gap: 1rem;
`;

const Finance = styled.div`
`;

const About = styled.div`
  height: 15vh;
`;

const PhotoBox = styled.div`
  background: url(${bonfireImageSmall}) no-repeat center;
  background-size:cover;
  border-radius: 0.5em;
`;

const PhotoAndContact = styled.div`
  height: 20vh;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "photo contact";
  grid-gap: 32px;
  
  ${PhotoBox} {
    grid-area: photo;

  }
`;

const ProfileContainer = styled(Container)`
  display: grid;
  grid-gap: ${({theme}) => theme.dimensions.margin};
  grid-template-rows: auto auto auto auto auto;
`;

export default EmployeeProfile;
