import React from "react";
import EmployeeContainer from "./employee/EmployeeContainer";
import ListingsContainer from "./listing/ListingsContainer";
import PaymentContainer from "./payment/paymentContainer";
import RequestContainer from "./request/requestContainer";
import PartnerContainer from "./partner/PartnerContainer";

import "./testDasboard.scss";


function TestDashboard() {
  return (
    <div className="dashboard">
      <h1>Circle Cloud Test Dashboard</h1>
      <EmployeeContainer />
      <PartnerContainer />
      <ListingsContainer />
      {/* <PaymentContainer />*/}
      <RequestContainer />
    </div>
  );
}
export default TestDashboard;
