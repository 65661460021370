import React from "react";
import Page from "@components/page";
import styled from "styled-components";
import {LinkButton} from "@components/button";
import {HeaderText, HeaderTextWrapper, SubHeaderText} from "@components/text";
import bonfireImageSmall from "../assets/start-welcome-image.png";

function Welcome() {
  return (
    <WelcomePage>
      <ImageWrapper />
      <HeaderTextWrapper zIndex={1} height={"27vh"} doublePadding>
        <HeaderText center={true}>
          WELCOME TO <div>GET PAID</div>
        </HeaderText>
        <SubHeaderText>
          Bartenders, service and catering staff, hostesses, cooks and cocktail masters.
          Let’s rock this party together!
        </SubHeaderText>
      </HeaderTextWrapper>
      <ButtonsWrapper>
        <StartPageButton href={"/login"} bgColor={"#9599B3"} fullWidth={true}>
          <span>LOGIN</span>
        </StartPageButton>
        <StartPageButton href={"#"} bgColor={"#241332"} fullWidth={true} zIndex={2}>
          <span>SIGN UP</span>
        </StartPageButton>
      </ButtonsWrapper>
    </WelcomePage>
  );
}

const ImageWrapper = styled.div`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 50%, rgba(0, 0, 0, 1) 100%),
  url(${bonfireImageSmall});
  background-size: cover;
  background-position: 52%;

  position: absolute;
  width: 100%;
  height: 55vh;
`;

const StartPageButton = styled(LinkButton)<Partial<{zIndex: number, overlap: boolean}>>`
  --frontButtonHeight: 55%;
  
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  height:  100%;
  border-top-left-radius: 80px;

  span {
    position: absolute;
    top: 20%;
  }

  &:nth-of-type(2) {
    height: var(--frontButtonHeight);
  }
  
  z-index: ${({zIndex}) => zIndex || 1};
`;

const ButtonsWrapper = styled.div`
  position: relative;
  height: 20vh;
`;

const WelcomePage = styled(Page)`
  display: grid;
  grid-template-areas:
          "ImageWrapper" 
  "HeaderTextWrapper" 
  "ButtonsWrapper";
  grid-template-rows: 1fr auto auto;
  
  ${ImageWrapper} {
    grid-area: ImageWrapper;
  }
  
  ${HeaderTextWrapper} {
    grid-area: HeaderTextWrapper;
  }
  
  ${ButtonsWrapper} {
    grid-area: ButtonsWrapper;
  }
`;

export default Welcome;
