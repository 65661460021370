import React from "react";
import styled from "styled-components";
import {Field} from "formik";

type inputProps = {
  label: string,
  type: string,
  name: string,
  width?: number,
  height?: number,
  id?: string,
  onChange?: any
};

const StyledInput = styled(Field)`
  font-weight: normal;
`;

const StyledFileInput = styled.input`
  font-weight: normal;
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  display: grid;
  grid-gap: 0.5em;
`;

export function Input({label, width, height, type, name, id}: inputProps) {
  return (
    <>
      <StyledLabel>
        {label}
        <StyledInput name={name} type={type} width={width} height={height} />
      </StyledLabel>
    </>
  );
}

export function FileInput({label, width, height, type, name, id, onChange}: inputProps) {
  return (
    <>
      <StyledLabel>
        {label}
        <StyledFileInput name={name} id={id} type={type} width={width} height={height} onChange={onChange} />
      </StyledLabel>
    </>
  );
}

