import React, {useContext, useEffect, useState} from "react";
import {AuthContext, authState} from "../user/userAuthContext";
import {RequestModel} from "@shared/models/request.model";
import {firestore} from "../init-firebase";
import {COLLECTION_EMPLOYEES, COLLECTION_NOTIFICATIONS, COLLECTION_REQUESTS} from "@shared/firebase-vars";
import styled, {css} from "styled-components";
import {SubHeaderText} from "@components/text";
import {Container} from "@components/container";
import Page from "@components/page";
import {goToApplicationPage} from "../helpers/redirects";
import {GPNotificationModel, notificationType} from "@shared/models/notification.model";

const tabKeys = ["My jobs", "Notifications"];

function Planning({history}: {history: any}) {
  const {user} = useContext<authState>(AuthContext);

  const [applications, setApplications] = useState<RequestModel[]>( [] );
  const [notifications, setNotifications] = useState<any>( [] );
  const [activeTab, setActiveTab] = useState<number>( 0 );

  function renderNotificationType(notification: GPNotificationModel, key: string|number) {
    switch (notification.type) {
      case notificationType.AcceptedRequest:
        return (
          <NotificationSmall
            key={key}
            onClick={() => goToApplicationPage(notification.listingId, notification.requestId, history) }>
            <div>
              <div>Application</div>
              <div>Congratulations! your application has been accepted</div>
            </div>
            <RightArrow />
          </NotificationSmall>
        );
    }
  }

  useEffect(() => {
    if (user) {
      const tempApplications: any[] = [];
      const unsubscribe = firestore
          .collectionGroup(COLLECTION_REQUESTS).where("employeeId", "==", user.uid)
          .onSnapshot( (colSnap) => {
            colSnap.docs
                .forEach( (doc) => {
                  const data = Object.assign({}, doc.data(), {id: doc.id});
                  return tempApplications.push(data);
                });
            setApplications(tempApplications);
          });

      const tempNotifications: any[] = [];
      const unsubscribe2 = firestore
          .collection(COLLECTION_EMPLOYEES)
          .doc(user.uid)
          .collection(COLLECTION_NOTIFICATIONS)
          .onSnapshot( (colSnap) => {
            colSnap.docs
                .forEach( (doc) => {
                  const data = Object.assign({}, doc.data(), {id: doc.id});
                  return tempNotifications.push(data);
                });
            setNotifications(tempNotifications);
          });

      return () => {
        unsubscribe();
        unsubscribe2();
      };
    }
  }, []);

  return (
    <PlanningPage>
      <>
        <PlanningContainer>
          <Tabs>
            <TabLabels>
              {tabKeys.map( (key: string, i: number) =>
                (
                  <StyledSubHeaderText onClick={() => setActiveTab(i)} active={activeTab === i} key={key} bold={true}>
                    {key}
                  </StyledSubHeaderText>
                ),
              )}
            </TabLabels>
            <div>
              {
                activeTab === 0 ?
                  applications.map( (application, i: number) =>
                    <ApplicationListItem
                      onClick={() => goToApplicationPage(application.listingId, application.id, history)} key={i}>
                      <div>
                        <div>
                          <div>{application.listingName}</div>
                          <div>{application.ownerName}</div>
                        </div>
                        <div>
                          {application.status}
                        </div>
                      </div>
                      <RightArrow />
                    </ApplicationListItem>,
                  ) :
                  activeTab === 1 ?
                    notifications.map((notification: GPNotificationModel, i: number) =>
                      renderNotificationType(notification, i),
                    ) :
                    null
              }
            </div>
          </Tabs>
        </PlanningContainer>
      </>
    </PlanningPage>
  );
}

const NotificationSmall = styled.div`
  position: relative;
  height: 5em;
  margin-bottom: 1em;
  display: grid;
  grid-auto-flow: column;
  align-content: center;
`;

const RightArrow = styled.span`
  justify-self: flex-end;
  align-self: center;
  &:after {
    content: ">";
    font-size: 20px;
    font-weight: bold;
    color: white;
  }
`;

const ApplicationListItem = styled.div<{height?: string|number}>`
  position: relative;
  height: ${({height}) => height || "5em" };
  margin-bottom: 1em;
  display: grid;
  grid-auto-flow: column;
  align-content: center;
`;

const TabLabels = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-bottom: 12px;
`;

const StyledSubHeaderText = styled(SubHeaderText)<{active: boolean}>`
  position: relative;

  ${({active}: any) => active && css`
    &:after {
      position: absolute;
      content: '';
      height: 4px;
      top: 24px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 75%;
      transition: .5s;
      background: white;
    }
  `}
`;

const Tabs = styled.div`
  position: relative;
`;

const PlanningContainer = styled(Container)<{theme: any}>`
  font-family: ${({theme}) => theme.fonts.secondary};
  height: 100%;
`;

const PlanningPage = styled(Page)`
  display: grid;
  grid-template-areas:
          "jobInfoImage"
  "content"
;

  grid-template-rows: auto 1fr;

  ${PlanningContainer} {
    grid-area: content;
    display: grid;
    grid-template-rows: min-content;
    grid-gap: 24px;
  }
`;

export default Planning;
