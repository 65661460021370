import React from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import {useTheme} from "./theme/useTheme";
import {GlobalStyles} from "./theme/GlobalStyles";
import UserAuthContext from "./user/userAuthContext";
import Routing from "./routes/routing";
import {NavBar} from "@components/navigation";

function App() {
  const {theme} = useTheme();

  return (
    <Router>
      <Switch>
        <UserAuthContext>
          <ThemeProvider theme={ theme }>
            <GlobalStyles />
            <NavBar />
            <Routing />
          </ThemeProvider>
        </UserAuthContext>
      </Switch>
    </Router>
  );
}
export default App;

