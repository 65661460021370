import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import {RequestModel} from "@shared/models/request.model";
import {firestore} from "../../init-firebase";
import {COLLECTION_LISTINGS, COLLECTION_REQUESTS} from "@shared/firebase-vars";
import {RequestStatus} from "@shared/enums/requestStatus.enum";

import "./request.scss";

function Request(requestObject: {request: RequestModel}) {
  const [request, setRequest] = useState<RequestModel>(requestObject.request);

  useEffect(()=>{
    setRequest(requestObject.request);
  });

  return (
    <div className="Request">
      <Formik
        initialValues={{status: request.status}}
        onSubmit={async ({status})=>{
          await updateRequest({status, id: request.id, listingId: request.listingId});
        }}
      >
        <Form className="form">
          <div>
            <label>id</label>
            {request.id}
          </div>
          <div>
            <label>status</label>
            <Field as="select" disabled={request.status === RequestStatus.error} name="status">
              {statusSelectOptions() }
            </Field>
          </div>
          <button disabled={request.status === RequestStatus.error} type="submit">Submit</button>
        </Form>
      </Formik>
    </div>
  );
}

function statusSelectOptions() {
  return Object.values(RequestStatus).map( (status, i) =>{
    return (
      <option key={i} value={status}>{status}</option>
    );
  } );
}

async function updateRequest({status, id, listingId}: Partial<RequestModel>) {
  console.log({status, id, listingId});
  await firestore
      .collection(COLLECTION_LISTINGS)
      .doc(listingId)
      .collection(COLLECTION_REQUESTS)
      .doc(id)
      .update({status});
}

export default Request;
