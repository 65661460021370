import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const initFirebase = {
  apiKey: "AIzaSyCswAOGolYDkcQzwyoMXDy9xbouPRpnqOE",
  authDomain: "circle-cloud-develop.firebaseapp.com",
  projectId: "circle-cloud-develop",
  storageBucket: "circle-cloud-develop.appspot.com",
  messagingSenderId: "39917130785",
  appId: "1:39917130785:web:095b33a2aacfdd5f47ff60",
};

if (!firebase.apps.length) {
  firebase.initializeApp(initFirebase);
} else {
  firebase.app();
}

const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

if (location.hostname === "localhost") {
  firestore.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099/");
  storage.useEmulator("localhost", 9199);
}

export default firebase;
export {firestore, auth, storage};
