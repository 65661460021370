import React, {useContext, useEffect, useState} from "react";
import {GPText, HeaderText, SubHeaderText} from "@components/text";
import Page from "@components/page";
import {Container} from "@components/container";
import styled from "styled-components";
import {firestore} from "../init-firebase";
import {COLLECTION_LISTINGS, COLLECTION_PARTNERS, COLLECTION_PRIVATE, DOCUMENT_FINANCE} from "@shared/firebase-vars";
import {ListingModel} from "@shared/models/listing.model";
import {StyledLink} from "@components/navigation";
import {Label} from "../jobs/JobCreate";
import {Button} from "@components/button";
import {Form, Formik} from "formik";
import {login} from "../helpers/auth";
import {Input, StyledLabel} from "@components/input";
import {Link} from "react-router-dom";
import {buyCredits, getCredits} from "../finance/finance.util";
import {AuthContext, authState} from "../user/userAuthContext";
import {EmployeeModel} from "@shared/models/employee.model";
import {PartnerModel} from "@shared/models/partner.model";
import {currencyFormatter} from "../helpers/currencyFormatter";

function Dashboard() {
  const {user} = useContext<authState<PartnerModel>>(AuthContext);

  const [jobs, setJobs] = useState<ListingModel[]>( [] );
  const [mangoId, setMangoId] = useState<string>( "" );
  const [balance, setBalance] = useState<number>( 0 );

  useEffect(() => {
    let data = null;
    const unsubscribe = firestore
        .collection(COLLECTION_LISTINGS)
        .onSnapshot( (colSnap) => {
          data = colSnap.docs.reduce( (acc: any, curr: any, i: number) => {
            const currData = curr.data();
            const listing = Object.assign({}, currData, {id: curr.id, date: currData.date.toDate().toLocaleDateString()});
            acc.push(listing);
            return acc;
          }, []);
          setJobs(data);
        });

    const unsubscribe2 = firestore
        .collection(COLLECTION_PARTNERS)
        .doc(user?.uid)
        .collection(COLLECTION_PRIVATE)
        .doc(DOCUMENT_FINANCE)
        .onSnapshot( async (colSnap) => {
          const mangoIdField = colSnap.get("mangoId");
          setMangoId(mangoIdField);
          setBalance(await getCredits(mangoIdField));
        });

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  return (
    <Page>
      <DashboardContainer>
        <HeaderText>Dashboard</HeaderText>

        <FinanceSection>
          <SubHeaderText bold>Wallet</SubHeaderText>

          <Balance>
            <StyledLabel>Balance</StyledLabel>
            <GPText fontSize={"32px"} bold>{currencyFormatter(balance)}</GPText>
          </Balance>
          <BuyCredit>
            {mangoId && <Formik
              initialValues={{amount: 0}}
              onSubmit={async ({amount}) => {
                await buyCredits(amount, mangoId);
              }}
            >
              <StyledForm>
                <Input name={"amount"} type={"number"} label={"Buy credits"}/>
                <Button type={"submit"}>Buy credits</Button>
              </StyledForm>
            </Formik>}
          </BuyCredit>
        </FinanceSection>

        <SubHeaderText bold>Listings</SubHeaderText>

        <StyledLink to={"job"}>
          <DashboardTile>
            <GPText bold>Create new listing</GPText>
          </DashboardTile>
        </StyledLink>

        {
          jobs.length > 0 ? jobs.map( (job, i) => (
            <StyledLink key={i} to={`job/${job.id}`}>
              <ListingRow>
                <span>{job.name}</span>
                <span>{job.date}</span>
              </ListingRow>
            </StyledLink>
          )): <div>You dont have any listings yet</div>
        }
      </DashboardContainer>
    </Page>
  );
}

const StyledForm = styled(Form)`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

const BuyCredit = styled.div`
  display: grid;
`;

const Balance = styled.div`
  display: grid;
`;

const FinanceSection = styled.div`
  margin-bottom: ${({theme}) => theme.dimensions.margin};
  display: grid;
  grid-template-areas:
          "header header"
          "balance balance";
  grid-template-columns: 1fr;
  
  ${SubHeaderText} {
    grid-area: header;
  }
  
  ${Balance} {
    grid-area: balance;
  }
`;

const ListingRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => `0 ${theme.dimensions.margin} `};
  height: ${({theme}) => `calc(${theme.dimensions.margin} * 3)`};
  margin: ${({theme}) => `${theme.dimensions.margin} 0`};
  background-color: ${({theme}) => theme.colors.grey};
`;

const DashboardTile = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: ${({theme}) => `calc(${theme.dimensions.margin} * 6)`};
  margin: ${({theme}) => `${theme.dimensions.margin} 0`};
  background-color: ${({theme}) => theme.colors.grey};
  border: ${({theme}) => `${theme.colors.accent} 3px solid`};
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.75);
`;

const DashboardContainer = styled(Container)<{theme: any}>`
  font-family: ${({theme}) => theme.fonts.secondary};
  height: 100%;
`;


export default Dashboard;
