import React from "react";
import {Redirect, Route} from "react-router-dom";
import {GPRoute, redirectToUserHomePage} from "./routing";
import {authState} from "../user/userAuthContext";

export default function ProtectedRoute(
    {route, authenticationState, key}: { route: GPRoute, authenticationState: authState, key: string | number },
) {
  const {redirectTo, path, exact, component, acceptedRoles} = route;
  const {checkingStatus, user, role} = authenticationState;

  if (!checkingStatus) {
    if (!user) {
      return <Route key={key} path={path} exact={exact}>
        <Redirect to={redirectTo || "/"}/>
      </Route>;
    } else if (user && (acceptedRoles && role && !acceptedRoles.includes(role))) {
      return redirectToUserHomePage(path, role, key);
    } else {
      return (
        <Route key={key} exact={exact} path={path} component={component} />
      );
    }
  } else {
    return null;
  }
}
