import React, {useEffect, useState} from "react";
import {firestore} from "../../init-firebase";
import {COLLECTION_LISTINGS} from "@shared/firebase-vars";
import {ListingModel} from "@shared/models/listing.model";
import Listing from "./Listing";

function ListingList() {
  const [listings, setListings] = useState<ListingModel[]>();

  useEffect(() => {
    const unsubscribe = firestore.collection(COLLECTION_LISTINGS).onSnapshot((snap) => {
      const data = snap.docs.map((d: any) => {
        const list: ListingModel = d.data() as ListingModel;
        return {...list, id: d.id};
      },
      );

      setListings(data);

      return function cleanup() {
        unsubscribe();
      };
    });
  }, []);

  return (
    <div>
      <h4>Listings</h4>

      {listings && listings.length > 0 ?
          listings?.map( (listing) => <Listing key={listing.id} listing={listing} />) :
          null}

    </div>
  );
}

export default ListingList;
