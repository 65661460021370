import {AxiosResponse} from "axios";

export type response<T> = { response: AxiosResponse<T> | any, error: any }

export async function resolve<T>(promise: Promise<any>) {
  const resolved: response<T> = {
    response: null,
    error: null,
  };

  try {
    resolved.response = await promise;
  } catch (e) {
    resolved.error = e;
  }

  return resolved;
}
