import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {company, internet, name} from "faker/locale/nl";
import {api} from "../../api";
import firebase from "firebase/app";

import "./partner.scss";
import {createPartnerAccount} from "@shared/models/partner.model";

function CreatePartner() {
  const [userCredential, setUserCredential] = useState<firebase.UserInfo>();

  return (
    <div className="Partner">
      <h4>Create Partner</h4>
      <Formik
        initialValues={{
          firstname: name.firstName(),
          lastname: name.lastName(),
          email: internet.email(),
          password: internet.password(10),
          kvk: 69599084,
          birthday: new Date(),
          organisationName: company.companyName(),
        }}
        onSubmit={async ({firstname, lastname, email, password, kvk, birthday, organisationName}) => {
          setUserCredential(await createPartner({firstname, lastname, email, password, kvk, birthday, organisationName}));
        }}
      >
        <Form>
          <div>
            <label>firstname</label>
            <Field name="firstname" type="text" />
          </div>
          <div>
            <label>lastname</label>
            <Field name="lastname" type="text" />
          </div>
          <label>email</label>
          <Field name="email" type="email" />
          <div>
            <label>organisationName</label>
            <Field name="organisationName" type="text" />
          </div>
          <div>
            <label>kvk</label>
            <Field name="kvk" type="number" />
          </div>
          <div>
            <label>birthday</label>
            <Field name="birthday" type="date" />
          </div>
          <label>password</label>
          <Field name="password" type="password" />
          <button type="submit">Submit</button>
        </Form>
      </Formik>
      {userCredential ?
          <div> Partner <span>{userCredential?.displayName}</span>
              with id: <span>{userCredential?.uid}</span> has been created</div>: null}
    </div>
  );
}

async function createPartner(partner: createPartnerAccount) {
  return await api.createPartner(partner);
}

export default CreatePartner;
