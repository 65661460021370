import React, {useEffect, useState} from "react";
import Page from "@components/page";
import {Container} from "@components/container";
import styled, {css} from "styled-components";
import {RequestModel} from "@shared/models/request.model";
import {firestore, storage} from "../init-firebase";
import {COLLECTION_LISTINGS, COLLECTION_REQUESTS} from "@shared/firebase-vars";
import {useParams} from "react-router-dom";
import {GPText, HeaderText} from "@components/text";
import {RequestStatus} from "@shared/enums/requestStatus.enum";
import {Button} from "@components/button";
import {completeApplication} from "./applicationActions";

function ApplicationStatus() {
  const {jobId, applicationId} = useParams<{jobId: string, applicationId: string}>();

  const [application, setApplication] = useState<RequestModel | null>( null );
  const [invoiceURL, setInvoiceURL] = useState<string | null>(null);

  const renderStateDetail = (param: string, jobId: string, request: RequestModel) => {
    switch (param) {
      case RequestStatus.requested:
        return (<div>
          Thanks for applying!
          We will notify you if the status of the application changes
        </div>);
      case RequestStatus.accepted:
        return (
          <div>
            <Button onClick={() => completeApplication(jobId, request)} bold>COMPLETE JOB & GET PAID</Button>
          </div>
        );
      case RequestStatus.completed:
        return (
          <InvoiceContainer>
            <GPText bold={true}>Invoice</GPText>
            {invoiceURL && <a href={invoiceURL}>Click here to download</a>}
          </InvoiceContainer>
        );
    }
  };

  useEffect(() => {
    const unsubscribe = firestore
        .collection(COLLECTION_LISTINGS)
        .doc(jobId)
        .collection(COLLECTION_REQUESTS)
        .doc(applicationId)
        .onSnapshot( async (colSnap) => {
          const data = colSnap.data() as any;
          const application = Object.assign({}, data, {id: colSnap.id});
          if (data) {
            setApplication(application);
            setInvoiceURL(await getInvoice(application));
          }
        });
    return () => {
      unsubscribe();
    };
  }, []);

  // TODO find better way for active statusLineDots
  return (
    <Page>
      {application && <ApplicationContainer>
        <Header>
          <HeaderText>Application</HeaderText>
        </Header>
        <Content>
          <GPText bold={true}>
            <div>{application.listingName}</div>
            <div>{application.ownerName}</div>
          </GPText>
          <div>
            <StatusLine>
              <StatusLineDot
                active={application?.status === RequestStatus.requested ||
                application?.status === RequestStatus.accepted ||
                application?.status === RequestStatus.completed
                }
              >
                <span>
              Applied
                </span>
              </StatusLineDot>
              <StatusLineDot
                active={application?.status === RequestStatus.requested ||
                application?.status === RequestStatus.accepted ||
                application?.status === RequestStatus.completed
                }
              >
                <span>
              Application received
                </span>
              </StatusLineDot>
              <StatusLineDot active={
                application?.status === RequestStatus.accepted ||
              application?.status === RequestStatus.completed}>
                <span>
              Application Accepted
                </span>
              </StatusLineDot>
              <StatusLineDot active={application?.status === RequestStatus.completed}
              >
                <span>
              Job Completed
                </span>
              </StatusLineDot>
            </StatusLine>
            {renderStateDetail(application.status, jobId, application)}
          </div>
        </Content>
      </ApplicationContainer>
      }
    </Page>
  );
}

const InvoiceContainer = styled.div`
  display: grid;
  grid-gap: ${({theme}) => theme.dimensions.margin};
`;

const Header = styled.div`
  height: ${({theme}) => `calc(${theme.dimensions.margin} * 4)`};
`;

const StatusLineDot = styled.div<{active: boolean}>`
  --lineHeight: 70px;

  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;

  &:after {
    position: absolute;
    content: '';
    height: 8px;
    margin: auto 0;
    Top: 0;
    Bottom: 0;
    width: calc(var(--lineHeight) + 1px);
    transform: rotate(-90deg);
    transform-origin: 0 -11px;
    transition: .5s;
    background: white;
    ${({active}: any) => active && css`
    background-color: ${({theme}) => theme.colors.accent}
  `}
  }

  &:nth-of-type(1) {
    &:after {
      content: none;
    }
  }

  &:nth-of-type(2) {
    top: var(--lineHeight);
  }

  &:nth-of-type(3) {
    top: calc(var(--lineHeight) * 2);
  }

  &:nth-of-type(4) {
    top: calc(var(--lineHeight) * 3);
  }

  & > span {
    position: absolute;
    left: 44px;
  }

  ${({active}: any) => active && css`
    background-color: ${({theme}) => theme.colors.accent}
  `}
`;

const StatusLine = styled.div`
  height: 100%;
`;

const Content = styled.div`
display: grid;
  grid-auto-rows: 10vh 50vh;

`;

const ApplicationContainer = styled(Container)`
  font-family: ${({theme}) => theme.fonts.secondary};
  height: 100%;


  display: grid;
  grid-auto-rows: min-content;
  grid-template-areas: "header" "content";

  ${Header} {
    grid-area: header;
  }

  ${Content} {
    grid-area: content;
  }
`;

async function getInvoice(request: RequestModel) {
  const listResult = await storage.ref(`${request.employeeId}/invoices/${request.id}`).listAll();
  if (listResult.items.length > 0) {
    return await listResult.items[0].getDownloadURL();
  }

  return null;
}

export default ApplicationStatus;
