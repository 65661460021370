import React from "react";
import styled from "styled-components";
import Page from "@components/page";
import {Container} from "@components/container";
import {Button} from "@components/button";
import {Link} from "react-router-dom";
import {Input} from "@components/input";
import {HeaderText, HeaderTextWrapper, SubHeaderText} from "@components/text";
import loginImage from "../assets/login.png";
import {Form, Formik} from "formik";
import {login} from "../helpers/auth";


function Login({history}: {history: any}) {
  return (
    <LoginPage>
      <ImageWrapper />
      <HeaderTextWrapper zIndex={1} height={"27vh"} doublePadding>
        <HeaderText center={true}>
          LOGIN <div>AND</div> <div>GET PAID</div>
        </HeaderText>
        <SubHeaderText>
          Bartenders, service and catering staff, hostesses, cooks and cocktail masters.
          Let’s rock this party together!
        </SubHeaderText>
      </HeaderTextWrapper>
      <FormWrapper doublePadding>
        <Formik
          initialValues={{email: "", password: ""}}
          onSubmit={async ({email, password}) => {
            await login(email, password, history);
          }}
        >
          <StyledForm>
            <Input name={"email"} type={"text"} label={"Email"}/>
            <Input name={"password"} type={"password"} label={"Password"}/>
            <Button type={"submit"}>Login</Button>
            <Link to={""}>No account? <b>Sign up</b></Link>
          </StyledForm>
        </Formik>
      </FormWrapper>
    </LoginPage>
  );
}

const ImageWrapper = styled.div`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 50%, rgba(0, 0, 0, 1) 100%),
  url(${loginImage});
  background-size: cover;
  background-position: 60%;

  position: absolute;
  width: 100%;
  height: 65vh;
`;

const StyledForm = styled(Form)`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

const FormWrapper = styled(Container)`
  position: relative;
  height: 45vh;
`;

const LoginPage = styled(Page)`
  display: grid;
  grid-template-areas:
  "ImageWrapper"
  "HeaderTextWrapper" 
  "FormWrapper" ;
  grid-template-rows: 1fr auto auto;

  ${ImageWrapper} {
    grid-area: ImageWrapper;
  }
  
  ${HeaderTextWrapper} {
    grid-area: HeaderTextWrapper;
  }
  
  ${FormWrapper} {
    grid-area: FormWrapper;
  }
`;

export default Login;
