import React, {useContext} from "react";
import Welcome from "../user/Welcome";
import Login from "../user/Login";
import JobsList from "../jobs/JobsList";
import {Redirect, Route} from "react-router-dom";
import {AuthContext, authState} from "../user/userAuthContext";
import TestDashboard from "../testDashboard/testDashboard";
import JobsDetail from "../jobs/JobsDetail";
import ApplicationStatus from "../applications/ApplicationStatus";
import Planning from "../planning/planning";
import EmployeeProfile from "../user/EmployeeProfile";
import {userRoles} from "@shared/enums/userRoles.enum";
import ProtectedRoute from "./protectedRoute";
import Dashboard from "../partners/dashboard";
import {JobCreate} from "../jobs/JobCreate";
import {JobAdmin} from "../jobs/JobAdmin";

const EMPLOYEE_HOME = "/jobs";
const PARTNER_HOME = "/dashboard";

export type GPRoute = {
  path: string,
  component: any,
  redirectOnAuth?: boolean,
  protectedAuth?: boolean,
  redirectTo?: string,
  exact?: boolean,
  acceptedRoles?: userRoles[],
  routes?: GPRoute[]
}

const routesConfig: GPRoute[] = [
  {
    path: "/",
    redirectTo: "/welcome",
    component: null,
    exact: true,
  },
  {
    path: "/welcome",
    component: Welcome,
    redirectOnAuth: true,
    exact: true,
  }, {
    path: "/login",
    component: Login,
    redirectOnAuth: true,
    exact: true,
  }, {
    path: "/jobs",
    component: JobsList,
    exact: true,
    protectedAuth: true,
    acceptedRoles: [userRoles.employee],
  }, {
    path: "/profile",
    component: EmployeeProfile,
    exact: true,
    protectedAuth: true,
    acceptedRoles: [userRoles.employee],
  }, {
    path: "/jobs/:id",
    component: JobsDetail,
    exact: true,
    protectedAuth: true,
    acceptedRoles: [userRoles.employee, userRoles.partner],
  }, {
    path: "/planning",
    component: Planning,
    exact: true,
    protectedAuth: true,
    acceptedRoles: [userRoles.employee],
  },
  {
    path: "/application/:jobId/:applicationId",
    component: ApplicationStatus,
    exact: true,
    protectedAuth: true,
    acceptedRoles: [userRoles.employee],
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
    protectedAuth: true,
    acceptedRoles: [userRoles.partner],
  },
  {
    path: "/job",
    component: JobCreate,
    exact: true,
    protectedAuth: true,
    acceptedRoles: [userRoles.partner],
  },
  {
    path: "/job/:id",
    component: JobAdmin,
    exact: true,
    protectedAuth: true,
    acceptedRoles: [userRoles.partner],
  }, {
    path: "/test",
    component: TestDashboard,
    exact: true,
  },
];


function Routing() {
  const authenticationState = useContext<authState>(AuthContext);
  return (
    <>
      {!authenticationState.checkingStatus && routesConfig.map((route, i) => {
        if (route.protectedAuth) {
          return ProtectedRoute({
            route,
            key: i,
            authenticationState});
        }

        if (route.redirectTo && !route.redirectOnAuth) {
          return redirect(route.path, route.redirectTo, route.exact, i);
        }

        if (route.redirectTo && route.redirectOnAuth && authenticationState.user) {
          return redirect(route.path, route.redirectTo, route.exact, i);
        }

        if (!route.redirectTo && route.redirectOnAuth && authenticationState.role) {
          return redirectToUserHomePage(route.path, authenticationState.role, i);
        }

        return <Route key={i} path={route.path} exact={route.exact} component={route.component} />;
      })}
    </>
  );
}

export function redirect(path: string, redirectTo?:string, exact?: boolean, key?: string|number) {
  return (
    <Route key={key} path={path} exact={exact}>
      <Redirect to={redirectTo || "/"}/>
    </Route>
  );
}

export function redirectToUserHomePage(path: string, role: userRoles, key: any) {
  if (role === userRoles.employee) {
    return redirect(path, EMPLOYEE_HOME, true, key);
  } else if (role === userRoles.partner) {
    return redirect(path, PARTNER_HOME, true, key);
  }
}

export default Routing;
