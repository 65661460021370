import React, {useEffect, useState} from "react";
import {HeaderText} from "@components/text";
import Page from "@components/page";
import {Container} from "@components/container";
import {firestore} from "../init-firebase";
import {COLLECTION_LISTINGS} from "@shared/firebase-vars";
import {ListingModel} from "@shared/models/listing.model";
import {JobListItem} from "./JobsListItem";
import styled from "styled-components";

function JobsList() {
  const [jobs, setJobs] = useState<ListingModel[] | null>( null );

  useEffect(() => {
    let data = null;
    const unsubscribe = firestore
        .collection(COLLECTION_LISTINGS)
        .onSnapshot( (colSnap) => {
          data = colSnap.docs.reduce( (acc: any, curr: any, i: number) => {
            const currData = curr.data();
            const listing = Object.assign({}, currData, {id: curr.id, date: currData.date.toDate().toLocaleDateString()});
            acc.push(listing);
            return acc;
          }, []);
          setJobs(data);
        });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <Page>
      <JobsListContainer>
        <HeaderText>Jobs</HeaderText>
        {
          jobs && jobs.map( (job: ListingModel, i: number) => (
            <JobListItem key={i} job={job} />
          ))
        }
      </JobsListContainer>
    </Page>
  );
}

const JobsListContainer = styled(Container)<{theme: any}>`
  font-family: ${({theme}) => theme.fonts.secondary};
  height: 100%;
`;


export default JobsList;
