import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import {ListingModel} from "@shared/models/listing.model";
import {EmployeeModel} from "@shared/models/employee.model";
import {firestore} from "../../init-firebase";
import {COLLECTION_EMPLOYEES, COLLECTION_LISTINGS, COLLECTION_REQUESTS} from "@shared/firebase-vars";

import "./requester.scss";
import {RequestModel} from "@shared/models/request.model";

function Requester() {
  const [listings, setListings] = useState<ListingModel[]>();
  const [employees, setEmployees] = useState<EmployeeModel[]>();

  useEffect(()=>{
    const unsubscribe = firestore.collection(COLLECTION_LISTINGS).onSnapshot((snap) => {
      const data = snap.docs.map((d: any) => {
        const list = d.data() as ListingModel;
        return {...list, id: d.id};
      },
      );

      setListings(data);
    });

    const unsubscribe2 = firestore.collection(COLLECTION_EMPLOYEES).onSnapshot((snap) => {
      const data = snap.docs.map((d: any) => {
        const list = d.data() as EmployeeModel;
        return {...list, uid: d.id};
      });

      setEmployees(data);
    });

    return function cleanup() {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  return (
    <div className="requester">
      <h4>create requests</h4>
      { employees && listings ? <Formik
        initialValues={{employeeUid: employees[0]?.uid, listingId: listings[0]?.id}}
        onSubmit={async ({employeeUid, listingId}: {employeeUid: string, listingId: string}) => {
          await request(
              find<EmployeeModel>(employees, employeeUid, "uid"),
              find<ListingModel>(listings, listingId, "id"),
          );
        }}>
        <Form className="form">
          <div>
            <label>Employee</label>
            <Field as="select" name="employeeUid">
              {selectOptions<EmployeeModel>(employees, "uid")}
            </Field>
          </div>
          <div>
            <label>Listing</label>
            <Field as="select" name="listingId">
              {selectOptions<ListingModel>(listings, "id")}
            </Field>
          </div>
          <button type="submit">Submit</button>
        </Form>
      </Formik>:
        <div>
          Loading...
        </div>}
    </div>
  );
}

function selectOptions<T>(values: T[], selector: string) {
  return values?.map( (value: { [key: string]: any }, i: number) =>{
    return (
      <option key={i} value={value[selector]}>{value[selector]}</option>
    );
  } );
}

async function request(employee: EmployeeModel, listing: ListingModel) {
  const request: Partial<RequestModel> = {
    employeeId: employee.uid,
    employeeName: employee.name,
    owner: listing.ownerId,
  };

  const documentReference = await firestore
      .collection(COLLECTION_LISTINGS)
      .doc(listing.id)
      .collection(COLLECTION_REQUESTS)
      .add(request);

  return documentReference.id;
}

function find<T>(values: T[], comparer: string, selector: string) {
  const found = values.find( (value: { [key: string]: any }) => {
    console.log(value[selector], comparer, selector, value);
    return value[selector] === comparer;
  });

  if (!found) throw Error(`${comparer} with selector ${selector} not found`);

  return found;
}

export default Requester;

