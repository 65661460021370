import React from "react";
import styled, {css} from "styled-components";
import {Button} from "@components/button";
import {GPText} from "@components/text";
import {ListingModel} from "@shared/models/listing.model";
import {JobInfoImage} from "./JobInfoImage";
import {StyledLink} from "@components/navigation";

export function JobListItem({job}: {job: ListingModel}) {
  return (
    <StyledLink to={`jobs/${job.id}`}>
      <JobListItemWrapper>
        <JobInfoImage job={job}/>
        <LowerHalf>
          <GPText className={"description"}>{job.description}</GPText>
          <InfoButton>More</InfoButton>
        </LowerHalf>
      </JobListItemWrapper>
    </StyledLink>
  );
}

const JobListItemWrapper = styled.div`
  display: grid;
  height: ${({theme}) => `calc(${theme.dimensions.margin} * 12)`};
  margin: ${({theme}) => `${theme.dimensions.margin} 0`};
  background-color: ${({theme}) => theme.colors.grey};
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.75);
`;

const InfoButton = styled(Button)`
  --width: 6em;
  
  width: var(--width);
  height: calc(var(--width) / 2);
  border-radius: calc(var(--width) / 3);
`;

const LowerHalf = styled.div`
  padding: ${({theme}) => `calc(${theme.dimensions.margin} / 2)`};

  ${({bgColor}: any) => bgColor && css`
    background-color: ${({theme}) => theme.colors.accent};
  `}
  
  display: grid;
  grid-template-areas: 
          'location .'
          'description .'
          'description info';
  
  ${InfoButton} {
    grid-area: info;
    justify-self: flex-end;
    align-self: flex-end;
  }
  
  .description {
   grid-area: description; 
  }
`;
