import React from "react";
import CreateEmployee from "./CreateEmployee";

import "./Employee.scss";

function EmployeeContainer() {
  return (
    <div className="Employee">
      <h3>Employee</h3>
      <CreateEmployee />
    </div>
  );
}

export default EmployeeContainer;
