import {ListingModel} from "@shared/models/listing.model";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import {ListingsStatus} from "@shared/enums/listingStatus.enum";
import {firestore} from "../../init-firebase";
import {COLLECTION_LISTINGS} from "@shared/firebase-vars";

import "./Listing.scss";

function Listing(listingObject: {listing: ListingModel}) {
  const [listing, setListing] = useState<ListingModel>(listingObject.listing);

  useEffect(()=>{
    setListing(listingObject.listing);
  });

  return (
    <div className="Listing">
      <Formik
        initialValues={{name: listing.name, status: listing.status, pax: listing.pax}}
        onSubmit={async ({name, status, pax})=>{
          await updateListing({name, status, pax, id: listing.id});
        }}
      >
        <Form className="form">
          <div>
            <label>name</label>
            <Field name="name" type="text"/>
          </div>
          <div>
            <label>pax</label>
            <Field name="pax" type="text"/>
          </div>
          <div>
            <label>status</label>
            <Field as="select" name="status">
              {statusSelectOptions() }
            </Field>
          </div>
          <button type="submit">Submit</button>
        </Form>
      </Formik>
    </div>
  );
}

function statusSelectOptions() {
  return Object.values(ListingsStatus).map( (status, i) =>{
    return (
      <option key={i} value={status}>{status}</option>
    );
  } );
}

async function updateListing({name, status, pax, id}: Partial<ListingModel>) {
  await firestore.collection(COLLECTION_LISTINGS).doc(id).update({name, status, pax});
}

export default Listing;
