import React from "react";
import styled, {css} from "styled-components";
import {themeType} from "../theme/GlobalStyles";
import {Link} from "react-router-dom";

type ButtonProps = Partial<{
  fullWidth: boolean,
    height: string|number,
    width: string,
    color: string,
  bgColor: string,
  bold: boolean,
    theme: themeType
}>;
export const Button = styled.button<ButtonProps>`
  color: ${({color, theme}) => color || theme.colors.text};
  width: ${({width}) => width || "100%"};
  height: ${({height}) => height || "5em" };
  background-color: ${({bgColor, theme}) => bgColor || theme.colors.accent};
  
  ${({bold}: any) => bold && css`
    font-weight: bold;
  `}
  
  ${({fullWidth}: any) => fullWidth && css`
    width: 100vw;
  `}
`;

type LinkButtonProps = { href: string, children?: any, className?: string, $fullWidth?: boolean} & ButtonProps;

const StyledLinkButton = styled(Link)<Omit<LinkButtonProps, "href">>`
  ${({$fullWidth}: any) => $fullWidth && css`
    width: 100vw;
  `}
`;

export function LinkButton({href, children, bgColor, fullWidth, className}: LinkButtonProps) {
  return (
    <StyledLinkButton $fullWidth={fullWidth} className={className} to={href}>
      <Button fullWidth={fullWidth} bgColor={bgColor} className={className}>
        {children}
      </Button>
    </StyledLinkButton>
  );
}
