import React, {useContext} from "react";
import styled from "styled-components";
import {Link, NavLink} from "react-router-dom";
import {AuthContext, authState} from "../user/userAuthContext";
import {Container} from "@components/container";
import {userRoles} from "@shared/enums/userRoles.enum";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const NavContainer = styled(Container)`
  font-family: ${({theme}) => theme.fonts.secondary};
  height: ${({theme}) => `calc(${theme.dimensions.margin} * 1.5)`};
`;

const Nav = styled.nav`
  display: grid;
  justify-content: space-around;
  align-content: center;
  grid-auto-flow: column;
`;

const StyledNavLink = styled(NavLink)`
  font-weight: bold;
    text-decoration: none;

  &.active {
    text-decoration: revert;
    text-decoration-thickness: .125em;
    text-underline-offset: 1.5px;
  }
`;

export function NavBar() {
  const {role} = useContext<authState>(AuthContext);

  return (
    role === userRoles.employee ?
      <NavContainer>
        <Nav>
          <StyledNavLink to="/jobs">Jobs</StyledNavLink>
          <StyledNavLink to="/planning">planning</StyledNavLink>
          <StyledNavLink to="/profile">Profile</StyledNavLink>
        </Nav>
      </NavContainer> :
      role === userRoles.partner ?
        <NavContainer>
          <Nav>
            <StyledNavLink to="/dashboard">Dashboard</StyledNavLink>
          </Nav>
        </NavContainer> :
      null
  );
}
