import React from "react";
import CreateListing from "./CreateListing";
import ListingList from "./ListingList";

import "./ListingsContainer.scss";

function ListingsContainer() {
  return (
    <div>
      <h3>Listings</h3>
      <div className="ListingsContainer">
        <CreateListing />
        <ListingList />
      </div>
    </div>
  );
}


export default ListingsContainer;
